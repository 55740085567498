import { Col, Divider, Row, Image, message, Button, Space, Card } from "antd";
import './index.css';
import V4 from './res/V4.jpg';
import V1 from './res/V1.jpg';
import V2 from './res/V2.jpg';
import V3 from './res/V3.jpg';
import { Link } from "react-router-dom";
import { searchHome, searchResHome } from "./api/skuapi";
import { JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState } from "react";
import Layout from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import Meta from "antd/lib/card/Meta";
const Body: React.FC = () => {
    const [skubody, setSkuBody] = useState<any>();
    const [resourceContent, setResourceContent] = useState<any>();
    const siderStyle: React.CSSProperties = {
        textAlign: 'center',
        color: '#fff',
        backgroundColor: '#fff',
        // width:"100px"
    };
    useEffect(() => {
        searchHome(data => {
            const skuList = data.data;
            if (!skuList) {
                return;
            }
            const skurow: any = [];
            skuList.forEach((sku: {
                fileTopImage: string | undefined;
                fileBrief: string;
                fileTitle: string;
                modifyDate: string; id: string; fileType: string; fileName: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined;
            }) => {
                skurow.push(
                    <div key={sku.id} className={"divdocumentrow"}>

                        <Layout>
                            <Link to={(sku.fileType === "md" ? "/mddocview/" : "/documentview/") + sku.id} >
                                <Sider style={siderStyle} >
                                    {/* <img width='100em' alt="文件图片" height='100em' src={sku.fileTopImage!==null?sku.fileTopImage
                                                                                : sku.fileType === 'txt' ? V1 
                                                                                : (sku.fileType === 'png' ? V2 
                                                                                : (sku.fileType === 'jpeg' ? V3 
                                                                                : (sku.fileType === 'xml' ? V4 
                                                                                : V1)))}></img> */}
                                    <Image
                                        // width={"100px"}
                                        style={{ "maxHeight": "130px" }}
                                        preview={false}
                                        src={sku.fileTopImage !== null ? sku.fileTopImage
                                            : sku.fileType === 'txt' ? V1
                                                : (sku.fileType === 'png' ? V2
                                                    : (sku.fileType === 'jpeg' ? V3
                                                        : (sku.fileType === 'xml' ? V4
                                                            : V1)))}
                                    />
                                </Sider>
                            </Link>
                            <Layout style={{ "backgroundColor": "#fff" }}>
                                <Link to={(sku.fileType === "md" ? "/mddocview/" : "/documentview/") + sku.id} >
                                    <div className="documenttitleStyle">{sku.fileTitle !== null ? sku.fileTitle : "-"}</div>
                                </Link>
                                <div className="documentbriefStyle">{sku.fileBrief !== null ? sku.fileBrief : "-"}</div>
                                <div className="documentDateStyle">{"日期：" + (sku.modifyDate !== null ? sku.modifyDate : "-")}</div>
                            </Layout>
                        </Layout>
                        {/* <Button type="primary">购买</Button> */}

                    </div>
                )
            });

         

            setSkuBody(skurow);



        }, faildata => {
            message.error(faildata);
        });
        searchResHome(data => {
            if (!data.data) {
                return;
            }
            console.log(data.data);
            const resHomeTmp: any[] = [];
            data.data.forEach((element: any) => {
                console.log(element);
                resHomeTmp.push(<>
                    <Col span={6}>
                        <Card
                            cover={
                                <Image
                                    alt={element.imageName}
                                    src={"/file/file/showthumbnail/" + element.fileId} 
                                    preview={{
                                        src:"/file/file/download/" + element.fileId
                                    }
                                    }
                                    />
                            }
                            actions={[
                                // <SettingOutlined key="setting" />,
                                // <EditOutlined key="edit" />,
                                // <EllipsisOutlined key="ellipsis" />,
                            ]}
                        >
                            <Meta
                                // avatar={<Avatar src="https://joesch.moe/api/v1/random" />}
                                title={element.resTitle}
                                description={(<><div style={{ "fontSize": "3px" }}>{element.createDate}</div>
                                    <div>{element.resBrief}</div></>)}
                            />
                        </Card>
                    </Col>
                </>)
            });
            setResourceContent(resHomeTmp);
        }, failed => {

        })
    }, []);
    return <div className="bodyStyle">
        <div className="ocrDivStyle" >
                <Divider orientation="left" className="ocrDividerStyle">
                    <span className="ocrListStyle">最近博客</span>
                </Divider><div>
                    <Space direction="vertical" style={{ width: '100%' }} size={8}>

                        {skubody}
                    </Space>
                </div>
                <Divider orientation="left" className="ocrDividerStyle">
                    <span className="ocrListStyle">最新素材</span>
                </Divider><div>
                    <Row gutter={[18, 18]} style={{ marginTop: "10px" ,textAlign:"left"}}>
                        {resourceContent}
                    </Row>
                </div>
            </div>
    </div>
}
export default Body;