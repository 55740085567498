// @ts-ignore
/* eslint-disable */
import { AxiosResponse } from 'axios';
import {get, post} from '../../../util/request';

export const searchMyOrder=(current:number,pageSize:number,success: (arg0: AxiosResponse<any, any>) => void,failed: (reason: any) => void)=>{
    post("/rest/order/searchMyOrder"+"?current="+current+"&pageSize="+pageSize,null).then(
        success
)
.catch(failed)
;
}
