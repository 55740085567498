// @ts-ignore
/* eslint-disable */
import { AxiosResponse } from 'axios';
import {get, post} from '../../../util/request';

export const selectDocument=(id:string,success: (arg0: AxiosResponse<any, any>) => void,failed: (reason: any) => void)=>{
    get("/editproject/filemanage/select/"+id,{}).then(
        success
)
.catch(failed);
}

export const saveDocument=(data:{id:number,fileName:string,fileTitle:string,fileType:string
    ,fileBrief:string,fileContent:string,fileTopImage:string},success: (arg0: AxiosResponse<any, any>) => void,failed: (reason: any) => void)=>{
    post("/editproject/filemanage/save",data).then(
        success
)
.catch(failed)
;
}
export const selectFileLabels=(id:string,success: (arg0: AxiosResponse<any, any>) => void,failed: (reason: any) => void)=>{
    get("/editproject/fileLabel/search/"+id,{}).then(
        success
)
.catch(failed);
}