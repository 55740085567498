import { Col, Pagination, Radio, Row, Space } from 'antd';
import Layout from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import { Image } from 'antd';

import { JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { searchLabels, searchOpenDocument } from './api/labelapi';
import './index.css';
import V4 from './res/V4.jpg';
import V1 from './res/V1.jpg';
import V2 from './res/V2.jpg';
import V3 from './res/V3.jpg';
const siderStyle: React.CSSProperties = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#fff',
};
const Document: React.FC = () => {
    const [labels, setLabels] = useState<any>();
    const [documentContent, setDocumentContent] = useState<any>();
    const [total,setTotal]=useState<number>();
    const [selectLableId,setSelectLabelId]=useState<number>();
    const searchDocument = (page:number,pageSize:number)=>{
        const searchData:any = {};
        searchData.labelId=selectLableId;
        searchOpenDocument(searchData, page, pageSize, data => {
            const documentlist = data.data.data;
            if (!documentlist) {
                return;
            }
            setTotal(data.data.total);
            const skurow: any = [];
            documentlist.forEach((sku: {
                fileTopImage: string | undefined;
                fileBrief: string;
                fileTitle: string;
                modifyDate: string; id: string; fileType: string; fileName: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined;
            }) => {
                skurow.push(
                    <div key={sku.id} className="divdocumentrow">

                        <Layout>
                            <Link  to={(sku.fileType === "md" ? "/mddocview/" : "/documentview/") + sku.id} >
                                <Sider style={siderStyle}>
                                <Image
                                    preview={false}
                                    style={{"maxHeight":"130px"}}
                                    src={sku.fileTopImage !== null ? sku.fileTopImage
                                        : sku.fileType === 'txt' ? V1
                                            : (sku.fileType === 'png' ? V2
                                                : (sku.fileType === 'jpeg' ? V3
                                                    : (sku.fileType === 'xml' ? V4
                                                        : V1)))}
                                />
                            </Sider>
                            </Link>
                            <Layout style={{ "backgroundColor": "#fff" }}>
                                <Link to={(sku.fileType === "md" ? "/mddocview/" : "/documentview/") + sku.id} >
                                    <div className="documenttitleStyle">{sku.fileTitle !== null ? sku.fileTitle : "-"}</div>
                                </Link>
                                <div className="documentbriefStyle">{sku.fileBrief !== null ? sku.fileBrief : "-"}</div>
                                <div className="documentDateStyle">{"日期：" + (sku.modifyDate !== null ? sku.modifyDate : "-")}</div>
                            </Layout>
                        </Layout>
                

                    </div>
                )
            });
            setDocumentContent(skurow);
        }, faildata => { })
    }
    useEffect(() => {
        searchLabels("DOCUMENT",data => {
            if (data.data !== undefined) {

                const labelContent: JSX.Element[] = [];
                data.data.forEach((element: {
                    labelName: any; id: any;
                }) => {
                    labelContent.push(<Radio.Button value={element.id} className='documentLabelcls'>{element.labelName}</Radio.Button>)
                });
                setLabels(labelContent);
            }

        }, faildResp => { });
    
       
       
    }, []);
    useEffect(() => {
        searchDocument(1,10);
    }, [selectLableId]);
    return <div className='documentdiv'>
        <div>
            <Radio.Group defaultValue="" 
            onChange={e=>{
                setSelectLabelId( e.target.value);
            }}
            buttonStyle="solid" className='documentLabelcls'>
                <Space>
                    <Radio.Button value="" className='documentLabelcls'>全部</Radio.Button>
                    {labels}
                </Space>
            </Radio.Group>
        </div>

        <Space direction='vertical' size={8} style={{ "width": "90%", marginTop: "2px" }}>
            {documentContent}
        </Space>
        <Pagination onChange={(page:number,pageSize:number)=>{searchDocument(page,pageSize)}} style={{textAlign:"right",marginRight:"10%",marginTop:"6px",fontSize:"12px"}}   pageSize={10} defaultCurrent={1} total={total} />
    </div>
}
export default Document;