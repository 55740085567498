import Banner from './banner';
import Body from './body';
import Header from './header';
import './index.css';
const Home: React.FC = () => {

    return <div >
        <Header></Header>
        <Body></Body>
    </div>
}
export default Home;