import { Button, Checkbox, Form, Input ,message, Space} from "antd";
import { login } from "./api/loginapi";
import './index.css';
import {  useNavigate } from 'react-router';
import { _setCurrentUser } from "../../context";
import { Link } from "react-router-dom";

const Login: React.FC = () => {
  const navigate = useNavigate()

  const onFinish = (values: any) => {
    console.log('Success:', values);
    login(values.username,values.password,data=>{
      if(data.data.code==="11"){
        _setCurrentUser(data.data.data);
      console.log(data);
      message.info(data.data.localeMessage);
      navigate("/home")
    }else{
      message.error(data.data.localeMessage);
    }
    },faildata=>{
      message.error(faildata);

    })
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return <div className="rootdiv">
    <div className="logintitle">用户登录</div>
    <Form
      name="basic"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="用户名: "
        name="username"
        rules={[{ required: true, message: 'Please input your username!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="密码: "
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 4, span: 20 }}>
        <Checkbox>记住密码</Checkbox>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
        <Space>
        <Button type="primary" htmlType="submit">
          登录
        </Button>
        {/* <Link to={"/userregister"}>
         注册
        </Link> */}
        </Space>
      </Form.Item>
    </Form>
  </div>
}
export default Login; 

function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
function push(arg0: string, param1: any): any {
  throw new Error("Function not implemented.");
}

