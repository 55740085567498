import { Col, Divider, Row, Image, message, Button } from "antd";
import './index.css';
import V4 from './res/V4.jpg';
import V1 from './res/V1.jpg';
import V2 from './res/V2.jpg';
import V3 from './res/V3.jpg';
import { Link, useParams } from "react-router-dom";
import { searchHome } from "./api/skuapi";
import { useEffect, useState } from "react";
const OutSku: React.FC = () => {
    const params = useParams();
    const [workHourUserId, setWorkHourUserId] = useState<string>();
    const [skubody, setSkuBody] = useState<any>();
    useEffect(() => {
    setWorkHourUserId(params.userId);
    searchHome(data => {
        const skuList = data.data.data;
        if (!skuList) {
            return;
        }
        let cateMap: Map<string, any> = new Map();
        skuList.forEach((sku: { categorieList: any[]; }) => {
            sku.categorieList.forEach(categorie => {
                let tmp: any = cateMap.get(categorie.name);
                if (!tmp) {
                    tmp = [];
                }
                tmp.push(sku);
                cateMap.set(categorie.name, tmp);
            });
        });
        console.log(cateMap);
        const content = [];
        // 使用对象解析
        for (let [key, skutmpList] of cateMap) {
            const skurow = [];
            for (let i = 0; i < skutmpList.length; i++) {
                skurow.push(<Col span={6} >
                    <div>
                        <Link to={"/skudetail/" + skutmpList[i].id}>
                            <img width='100em' alt="商品图标" height='100em' src={skutmpList[i].skuImage === '1' ? V1 : (skutmpList[i].skuImage === '2' ? V2 : (skutmpList[i].skuImage === '3' ? V3 : (skutmpList[i].skuImage === '4' ? V4 : V1)))}></img>
                            <div className="ocrTextStyle">{skutmpList[i].name}</div>
                            <Button type="primary">购买</Button>
                        </Link>
                    </div>
                </Col>)
            }

            content.push(<div className="ocrDivStyle">
                <Divider orientation="left" className="ocrDividerStyle">
                    <span className="ocrListStyle">{key}</span>
                </Divider><div>
                    <Row gutter={[16, 16]}>

                        {skurow}

                    </Row>
                </div>
            </div>);

            setSkuBody(content);


        }

    }, faildata => {
        message.error(faildata);
    })
    },[]);
    return <div className="bodyStyle">
    {skubody}
</div>
}
export default OutSku;