// @ts-ignore
/* eslint-disable */
import { AxiosResponse } from 'axios';
import {get,post} from '../../../util/request';

export const selectorder=(orderId:any,success: (arg0: AxiosResponse<any, any>) => void,failed: (reason: any) => void)=>{
    get("/rest/order/select/"+orderId).then(
        success
)
.catch(failed)
;
}
export const selectPay=(payId:any,success: (arg0: AxiosResponse<any, any>) => void,failed: (reason: any) => void)=>{
    get("/rest/pay/select/"+payId).then(
        success
)
.catch(failed)
;
}
