
import { Button, message, Modal, Space, Upload, UploadFile, UploadProps } from "antd";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import TextArea from "antd/lib/input/TextArea";
import CheckableTag from "antd/lib/tag/CheckableTag";
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { saveDocument } from "../documentedit/api/documentapi"
import ImgCrop from 'antd-img-crop';
import { RcFile } from "antd/lib/upload";
import { saveResource, selectResource } from "./api/imagerespai";
import { searchLabels } from "../document/api/labelapi";

const ImageDocEdit: React.FC = (props: any) => {
  const params = useParams();
  const [tags, setTags] = useState<any[]>([]);
  const [resId,setResId]=useState<string>();
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>();
  const [fileTitle, setFileTitle] = useState<string>();
  const [fileBrief,setFileBrief]=useState<string>();
  const [fileImg, setFileImg] = useState<string>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const openStateonChange = (e: CheckboxChangeEvent) => {
    setIsOpen(e.target.checked);
  };
  const existTag = (tag: any) => {
    let res = false;
    selectedTags.forEach(selectTag => {
      if (selectTag.id === tag.id) {
        console.log("exist", tag, selectTag);
        res = true;
      }
    })
    return res;
  }
  const fileTileOnChange = (data: { target: { value: React.SetStateAction<string | undefined>; }; }) => {
    setFileTitle(data.target.value)
  }
  const fileBriefOnChange = (data: { target: { value: React.SetStateAction<string | undefined>; }; }) => {
    setFileBrief(data.target.value)
  }
  const handleChange = (tagtmp: any, checked: boolean) => {
    const nextSelectedTags = checked ? [...selectedTags, tagtmp] : selectedTags.filter(t => t.id !== tagtmp.id);
    setSelectedTags(nextSelectedTags);
  };
  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {

    let fileListtmp: UploadFile[] = [];
    if (newFileList) {
      newFileList.forEach(file => {
        if (file.response) {
          fileListtmp.push({
            uid: file.response.data.id,
            name: file.response.data.srcFileName,
            status: file.response.status,
            url: "/file/file/download/" + file.response.data.id,
            thumbUrl:"/file/file/showthumbnail/" + file.response.data.id,
          })
        } else {
          fileListtmp.push(file);
        }
      })
    }
    setFileList(fileListtmp);
  };
  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    console.log(file);

    setPreviewImage(src || (file.preview as string));
    setPreviewOpen(true);
  };
  const handleCancel = () => setPreviewOpen(false);

  const onSave = () => {
    let data: any = {};
    data.id = resId;
    data.resTitle = fileTitle;
    data.resType = "IMG";
    data.resBrief = fileBrief;
    data.openState = isOpen ? "YES" : "NO";
    data.topImage = fileImg;
    if (selectedTags) {
      const labelManages: any[] = [];
      selectedTags.forEach(tag => {
        labelManages.push({
          labelId: tag.id,
          labelName: tag.labelName,
          labelType: tag.labelType,
          seq: tag.seq
        })
      });
      data.labelManages = labelManages;
    }
    if (fileList) {
      const imageManages: any[] = [];
      let index = 1;
      fileList.forEach(file => {
        imageManages.push({
          fileId: file.uid,
          imageName: file.name,
          seq: index
        });
        index++;
      });
      data.resImages = imageManages;
    }
    saveResource(data, resp => {
setResId(resp.data.id);
      message.success("保存成功！");
    }, faildata => {
      message.error(faildata);
    })
  };
  const loadResource = (id: string) => {
    if (!id) {
      return;
    }
    selectResource(id, data => {
      setFileTitle(data.data.resTitle);
      setIsOpen(data.data.openState.code === "YES" ? true : false);
      setFileImg(data.data.topImage);
      setFileBrief(data.data.resBrief);
      if (data.data.labelManages) {
        const selectTagTmp: object[] = [];
        data.data.labelManages.forEach((tagtmp: any) => {
          selectTagTmp.push({
            id: tagtmp.labelId,
            labelName: tagtmp.labelName,
            labelType: tagtmp.labelType,
            seq: tagtmp.seq
          })
        });
        setSelectedTags(selectTagTmp);
      }

      if (data.data.resImages) {
        let fileListtmp: UploadFile[] = [];
        data.data.resImages.forEach((file: any) => {
          fileListtmp.push({
            uid: file.fileId,
            name: file.imageName,
            status: "done",
            url: "/file/file/showthudownloadmbnail/" + file.fileId,
            thumbUrl:"/file/file/showthumbnail/" + file.fileId,
          })

        })
        setFileList(fileListtmp);
      }
    }, faild => {
      message.error(faild);
    })
  }
  useEffect(() => {
    console.log(params.id);
setResId(params.id);
    searchLabels("RESOURCE", data => {
      if (data.data !== undefined) {
        setTags(data.data);
      }
      if (params.id) {
        loadResource(params.id);
      }
    }, faildResp => { });

  }, [params.id]);
  return <>
    <div style={{ marginTop: "10px", marginLeft: "50px", marginRight: "50px", textAlign: "left" }}>
      <span ><Checkbox checked={isOpen} onChange={openStateonChange}>是否公开</Checkbox></span>
      <span style={{ marginLeft: "0%" }}>分类：
        {tags.map(tag => (
          <CheckableTag
            key={tag.id}
            checked={existTag(tag)}
            onChange={checked => handleChange(tag, checked)}
          >
            {tag.labelName}
          </CheckableTag>
        ))}
      </span>

      <Button type="primary" style={{ float: "right" }} onClick={onSave}>保存</Button>
    </div>
    <div style={{ marginLeft: "50px", marginRight: "50px", marginTop: "10px" }}>
    
      <TextArea placeholder="标题" autoSize={{ minRows: 1, maxRows: 4 }} value={fileTitle} onChange={fileTileOnChange} />
    </div>
    <div style={{ marginLeft: "50px", marginRight: "50px", marginTop: "10px" }}>
    
      <TextArea placeholder="描述" autoSize={{ minRows: 1, maxRows: 4 }} value={fileBrief} onChange={fileBriefOnChange} />
    </div>
    <div style={{ marginLeft: "50px", marginRight: "50px", marginTop: "5px" }}>
      {/* <ImgCrop rotationSlider> */}
        <Upload
          action="/file/file/upload"
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onPreview={onPreview}
        >
          {fileList.length < 100 && '+ Upload'}
        </Upload>
      {/* </ImgCrop> */}
      <Modal open={previewOpen} footer={null} onCancel={handleCancel}>
        <img alt="" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  </>
}
export default ImageDocEdit;