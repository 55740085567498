// @ts-ignore
/* eslint-disable */
import { AxiosResponse } from 'axios';
import {del, get, post} from '../../../util/request';

export const searchDocument=(current:number,pageSize:number,searchData:any,success: (arg0: AxiosResponse<any, any>) => void,failed: (reason: any) => void)=>{
    post("/editproject/filemanage/search"+"?current="+current+"&pageSize="+pageSize,searchData).then(
        success
)
.catch(failed)
;
}

export const deleteDocument=(documentId:number,success: (arg0: AxiosResponse<any, any>) => void,failed: (reason: any) => void)=>{
    del("/editproject/filemanage/delete/"+documentId).then(
        success
).catch(failed);
}
