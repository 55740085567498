// @ts-ignore
/* eslint-disable */
import { AxiosResponse } from 'axios';
import {get,post} from '../../../util/request';

export const selectsku=(skuId:any,success: (arg0: AxiosResponse<any, any>) => void,failed: (reason: any) => void)=>{
    get("/rest/sku/select/"+skuId).then(
        success
)
.catch(failed)
;
}
export const insertOrder=(data:any,success: (arg0: AxiosResponse<any, any>) => void,failed: (reason: any) => void)=>{
    post("/rest/order/insert",data).then(
        success
)
.catch(failed)
;
}
