// @ts-ignore
/* eslint-disable */
import { AxiosResponse } from 'axios';
import { del, get, post } from '../../../util/request';

export const searchResource = (current: number, pageSize: number, searchData: any, success: (arg0: AxiosResponse<any, any>) => void, failed: (reason: any) => void) => {
    post("/editproject/resmanage/search" + "?current=" + current + "&pageSize=" + pageSize, searchData).then(
        success
    )
        .catch(failed)
        ;
}
export const deleteResource = (id: number, success: (arg0: AxiosResponse<any, any>) => void, failed: (reason: any) => void) => {
    del("/editproject/resmanage/delete/" + id).then(success).catch(failed);
}
