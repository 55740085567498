// @ts-ignore
/* eslint-disable */
import { AxiosResponse } from 'axios';
import {get, post} from '../../../util/request';

export const searchLabels=(classifyCode:string,success: (arg0: AxiosResponse<any, any>) => void,failed: (reason: any) => void)=>{
    get("/editproject/label/searchLabels/"+classifyCode).then(
        success
)
.catch(failed)
;
}
export const searchOpenDocument=(data:any,current:number,pageSize:number
    ,success: (arg0: AxiosResponse<any, any>) => void,failed:(arg0: AxiosResponse<any, any>) => void)=>{
    post("/editproject/filemanage/searchOpenDocument"+"?current="+current+"&pageSize="+pageSize,data).then(
        success
).catch(failed);
}