// @ts-ignore
/* eslint-disable */
import { AxiosResponse } from 'axios';
import {post} from '../../../util/request';

export const login=(username:string,password:string,success: (arg0: AxiosResponse<any, any>) => void,faild: (reason: any) => void)=>{
    post("/rest/loginjson",{
        username: username,
        password: password
      }).then(
        success
).catch(faild);
}
export const registeruser=(registeruser:any,success: (arg0: AxiosResponse<any, any>) => void,faild: (reason: any) => void)=>{
  post("/rest/register/registeruser",registeruser).then(
      success
).catch(faild);
}