import { Button, Col, Collapse, Dropdown, Input, List, Image, Menu, Pagination, Row, Space, Tag, MenuProps, message, Popconfirm } from "antd";

import { Key, useEffect, useState } from "react";
import { SettingOutlined } from '@ant-design/icons';
import { ExpandIconPosition } from "antd/lib/collapse/Collapse";
import { deleteResource, searchResource } from "./api/resourceapi";
import "./index.css";
const { Search } = Input;
const { Panel } = Collapse;

const menu = (
    <Menu>
        <Menu.Item>
            <a target="_self" rel="img" href="/imagedocedit">
                Image
            </a>
        </Menu.Item>
    </Menu>
);
const MyResource: React.FC = (props: any) => {
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [expandIconPosition, setExpandIconPosition] = useState<ExpandIconPosition>('start');
    const [myResourceList, setMyResourceList] = useState([]);
    const [myResourceCollapse, setMyResourceCollapse] = useState([]);
    const onPositionChange = (newExpandIconPosition: ExpandIconPosition) => {
        setExpandIconPosition(newExpandIconPosition);
    };

    const onChange = (key: string | string[]) => {
        console.log(key);
    };

    const onSearchEvent = (value: string) => {
        onSearch(page, pageSize, value);
    };
    const onSearch = (pagetmp: number, pageSizeTmp: number, val?: string) => {
        searchResourcefun(pagetmp,pageSizeTmp,val);
    };
    const onDelete=(id:number)=>{
        
        deleteResource(id,data=>{
            message.info("删除成功")
            searchResourcefun(page, pageSize);

        },failed=>{
            message.error(failed);
        });
    }

    const genExtra = (element:any) => {
        const items: MenuProps['items'] = [
            {
                key: '1',
                label: (
                    <a target="_blank" rel="noopener noreferrer" href={"/imagedocedit/"+element.id}>
                        编辑
                    </a>
                ),
            },
            {
                key: '2',
                label: (
                    <Popconfirm
                    placement="topRight"
                    title={"是否删除"}
                    onConfirm={e=>{
                     
                        onDelete(element.id)}}
                     
                    okText="Yes"
                    cancelText="No"
                  >
                        删除
                    </Popconfirm>
                ),
            },
        ];
        
        return (<Dropdown menu={{ items }}>
            <span onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}>
                <Space>
                    <SettingOutlined />
                </Space>
            </span>
        </Dropdown>)
    };
    const searchResourcefun = (page: number, pageSize: number,searchKey?:string) => {
        const searchData = {
            key:searchKey
        };
        const myResourceCollapseTmp: any = [];

        searchResource(page, pageSize, searchData, data => {
            setTotal(data.data.total);
            setMyResourceList(data.data.data);
            if (data.data.data) {
                data.data.data.forEach((element: any) => {
                    const myResourceImgTmp: any = [];
                    const tags: any[] = [];
                    const tagcolors: string[] = ["red", "magenta", "volcano", "orange", "gold",
                        "lime", "green", "cyan", "blue", "geekblue", "purple"]
                    console.log(element);
                    if (element.resImages) {
                        element.resImages.forEach((element: any) => {
                            myResourceImgTmp.push(<Col span={4} >
                                <div>
                                    <Image
                                        width={100}
                                        className={"imageborder"}
                                        src={"/file/file/showthumbnail/" + element.fileId}
                                        preview={{
                                            src:"/file/file/download/" + element.fileId
                                        }
                                        }
                                    />
                                    <div className="imagename" title={element.imageName}>
                                        {element.imageName}
                                    </div>
                                </div>
                            </Col>)
                        });
                    }
                    if (element.labelManages) {
                        let colorindex = 0;
                        element.labelManages.forEach((element: any) => {
                            tags.push(
                                <Tag color={tagcolors[colorindex % 11]}>{element.labelName}</Tag>
                            )
                            colorindex++;
                        })
                    }
                    const headerdiv = <><span>
                        <Space>
                            {element.resTitle}
                            {tags}
                        </Space>
                        <span style={{ float: "right", marginRight: "20px" }}>
                            {element.createDate}
                        </span>
                    </span></>;
                    myResourceCollapseTmp.push(

                        <Panel header={headerdiv} className="panelcls" key={element.id} extra={genExtra(element)}>
                            <Row gutter={[16, 16]}>
                                {
                                    myResourceImgTmp
                                }

                            </Row>
                        </Panel>

                    )
                });
                setMyResourceCollapse(myResourceCollapseTmp);
            }

        }, faild => { });
    }
    useEffect(() => {
        searchResourcefun(page, pageSize)

    }, []);
    
    return <>
        <div style={{ marginTop: "3px" }}>

            <Space
                direction="horizontal"
                align='end'
            >
                <Search
                    placeholder="input search text"
                    allowClear
                    enterButton="Search"
                    size="small"
                    onSearch={onSearchEvent}
                />

                <Dropdown overlay={menu} placement="bottom"  >
                    <Button type="primary" size='small'>新增</Button>
                </Dropdown>
            </Space>
        </div>
        <Collapse
            onChange={onChange}
            expandIconPosition={expandIconPosition}

        >

            {myResourceCollapse}
        </Collapse>
        <Pagination
            pageSize={pageSize}
            current={page}
            total={total}
            showSizeChanger
            showQuickJumper
            showTotal={(total) => `Total ${total} items`}
        />
    </>
}
export default MyResource;

