import { Carousel, Menu, Image } from "antd";
import { HomeOutlined, SnippetsOutlined, TeamOutlined } from '@ant-design/icons';
import { useState } from "react";
import face from './res/face.jpeg';
import facecompare from './res/facecompare.jpeg';
import card from './res/card.jpeg';
import textocr from './res/textocr.jpeg';
import './index.css';
import { url } from "inspector";
import { Link } from "react-router-dom";
const Header: React.FC = () => {
  const [current, setCurrent] = useState<string>("home");
  return <div>

    {/* <div className="carouseDivStyle">
      
    </div> */}
  </div>
}
export default Header;