// @ts-ignore
/* eslint-disable */
import { AxiosResponse } from 'axios';
import {post} from '../../../../util/request';

export const searchHome=(success: (arg0: AxiosResponse<any, any>) => void,failed: (reason: any) => void)=>{
    post("/editproject/filemanage/search/home",{}).then(
        success 
)
.catch(failed)
;
}
export const searchResHome=(success: (arg0: AxiosResponse<any, any>) => void,failed: (reason: any) => void)=>{
    post("/editproject/resmanage/search/home",{}).then(
        success 
)
.catch(failed)
;
}
