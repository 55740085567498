import { useEffect, useState } from 'react';
import { _getCurrentUser, _getShowName } from "../../context";
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import './index.css';
import React from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { Button, Checkbox, message, Space } from 'antd';
import { useParams } from 'react-router-dom';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
// import style manually
import 'react-markdown-editor-lite/lib/index.css';
import Modal from 'antd/lib/modal/Modal';
import { postFile } from '../../util/request';
import { saveDocument, selectDocument, selectFileLabels } from '../documentedit/api/documentapi';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { searchLabels } from '../document/api/labelapi';



const MdDocEdit: React.FC = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileTitle, setFileTitle] = useState<string>();
  const [fileContent, setFileContent] = useState<string>();
  const [fileBrief, setFileBrief] = useState<string>();
  const [fileImg, setFileImg] = useState<string>();
  const [documentId, setDocumentId] = useState<string>();
  const [document, setDocument] = useState<any>();
  const params = useParams();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([]);
  const openStateonChange = (e: CheckboxChangeEvent) => {
    setIsOpen(e.target.checked);
  };
  const handleChange = (tagtmp: any, checked: boolean) => {
    const nextSelectedTags = checked ? [...selectedTags, tagtmp] : selectedTags.filter(t => t.id !== tagtmp.id);
    setSelectedTags(nextSelectedTags);
  };
  const existTag=(tag:any)=>{
    let res=false;
    selectedTags.forEach(selectTag=>{
      if(selectTag.id===tag.id){
        res= true;
      }
    })
    return res;
  }
  // 检测markdown数据变化
  function handleEditorChange(data: {
    text: string;
    html: string;
  }) {
    setFileContent(data.text);
    setFileBrief(data.text);
  }
  const onSelect = () => {
    if (params.documentId) {
      selectDocument(params.documentId, data => {
        if (data.data) {
          setDocument(data.data);
          setFileTitle(data.data.fileTitle);
          setFileContent(data.data.fileContent);
          setIsOpen(data.data.openState.code === "YES" ? true : false);
          setFileImg(data.data.fileTopImage)
        }
      }, faildata => {
        message.error(faildata);
      });
    }
    if(params.documentId){
     
      selectFileLabels(params.documentId,data=>{
        if(data.data){
          const selectTagTmp:object[]=[];
          data.data.forEach((tagtmp:any)=>{
            selectTagTmp.push({
              id:tagtmp.labelId,
              labelName:tagtmp.labelName,
              labelType:tagtmp.labelType,
              seq:tagtmp.seq
            })
          });
          setSelectedTags(selectTagTmp);
        }
      },failddata=>{
    message.error(failddata);
      });
    }
  };
  const onSave = () => {
    let data: any = {};
    if (params.documentId) {
      data.id = params.documentId;
    } else {
      data.id = documentId
    }
    data.fileName = fileTitle;
    data.fileTitle = fileTitle;
    data.fileType = "md";
    data.fileTopImage=fileImg;
    data.fileBrief = fileContent?.substring(0,50);
    data.fileContent = fileContent;
    data.openState = isOpen ? "YES" : "NO";
    
    if(selectedTags){
      const labelManages:any[] = [];
      console.log(selectedTags);
      selectedTags.forEach(tag=>{
        labelManages.push({
          labelId:tag.id,
          labelName:tag.labelName,
          labelType:tag.labelType,
          seq:tag.seq
        })
      });
      data.labelManages=labelManages;
    }
    saveDocument(data, resp => {
      setDocumentId(resp.data.id);
      console.log("保存成功！")
      message.success("保存成功！");
    }, faildata => {
      message.error(faildata);
    })
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const fileTileOnChange = (data: { target: { value: React.SetStateAction<string | undefined>; }; }) => {
    setFileTitle(data.target.value);
  }
  const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
    breaks:true,
    highlight: function (str, lang) {
      if (lang  && hljs.getLanguage(lang)) {
        try {
          return '<pre class="hljs codeStyle"><code>' +
          hljs.highlight(str,{ language: lang, ignoreIllegals: true }).value+
          '</code></pre>';
        } catch (__) {}
      }
  
      return '<pre class="hljs codeStyle"><code>' +str + '</code></pre>';
    }
  }).enable('image');
  useEffect(() => {
    setDocumentId(params.documentId);
    onSelect();
    searchLabels("DOCUMENT",data => {
      if (data.data !== undefined) {
      setTags(data.data);
      }
      
    }, faildResp => { });
  }, [params.documentId]);

  return <div>
    <div style={{  marginLeft: "50px",marginRight: "50px", marginTop: "10px" ,textAlign:"left" }}>
    <span ><Checkbox checked={isOpen} onChange={openStateonChange}>公开</Checkbox></span>
      <span style={{ marginLeft: "0%" }}>
        {/* <span style={{"fontStyle":"normal","fontWeight":"bold" ,marginLeft:"20px",padding:"2px"}}>标签：</span> */}
        {tags.map(tag => (
          // <span style={{"borderStyle":"dashed","borderColor":"gray",borderWidth:"1px"}}>
          <CheckableTag
            key={tag.id}
            style={{"borderStyle":"dashed","borderColor":"gray",borderWidth:"1px"}}
            checked={existTag(tag)}
            onChange={checked => handleChange(tag, checked)}
          >
            {tag.labelName}
          </CheckableTag>
          // </span>
        ))}
      </span>

      <Button type="primary" style={{ float: "right" }} onClick={onSave}>保存</Button>
    </div>
    <div style={{ marginLeft: "50px", marginRight: "50px", marginTop: "14px" }}>
      <TextArea placeholder="标题" autoSize={{ minRows: 1, maxRows: 4 }} value={fileTitle} onChange={fileTileOnChange} />
    </div>
    <MdEditor style={{ minHeight: '500px',marginTop: "8px" ,marginLeft:"8px",marginRight:"8px" }}
      value={fileContent}
      renderHTML={text => mdParser.render(text)}
      htmlClass="mdview"
      onChange={handleEditorChange}
      placeholder=" Bash C C# C++ CSS Diff Go GraphQL HTML, XML JSON Java JavaScript Kotlin Less Lua Makefile Markdown Objective-C PHP PHP Template Perl Plain text Python Python REPL R Ruby Rust SCSS SQL Shell Session Swift TOML, also INI TypeScript Visual Basic .NET WebAssembly YAML"
      onImageUpload={
        async (file: any) => {
          const formData = new FormData()
          formData.append('file', file)
          let result = await postFile("/file/file/upload", formData)
          if (result.data.code === "00001") {
            setFileImg("/file/file/download/" + result.data.data.id);
            return "/file/file/download/" + result.data.data.id;
          }
          message.error(result.data.localeMessage);
        }
      }

    />

  </div>
}
export default MdDocEdit;