// @ts-ignore
/* eslint-disable */
import { AxiosResponse } from 'axios';
import {get, post} from '../../../util/request';

export const selectResource=(id:string,success: (arg0: AxiosResponse<any, any>) => void,failed: (reason: any) => void)=>{
    get("/editproject/resmanage/select/"+id,{}).then(
        success
)
.catch(failed);
}

export const saveResource=(data:any,success: (arg0: AxiosResponse<any, any>) => void,failed: (reason: any) => void)=>{
    post("/editproject/resmanage/save",data).then(
        success
)
.catch(failed)
;
}
export const selectFileLabels=(id:string,success: (arg0: AxiosResponse<any, any>) => void,failed: (reason: any) => void)=>{
    get("/editproject/fileLabel/search/"+id,{}).then(
        success
)
.catch(failed);
}