import { Avatar, Button, Col, Dropdown, Layout, List, Menu, MenuProps, message, Row, Space, Table, Tag } from 'antd';
import { useEffect, useState } from 'react';
import './index.css';
import { _getCurrentUser, _getShowName } from "../../context";
import { Input } from 'antd';


import React from 'react';

const MindDocEdit: React.FC = (props: any) => {
  useEffect(() => {

  }, []);

  return <div>
   
  </div>
}
export default MindDocEdit;