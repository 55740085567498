import { Button, Form, Input, InputNumber } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { _getCurrentUser } from "../../context";
import  './index.css';
const UserCenter: React.FC = () => {
    const [forminstance] = Form.useForm()
    useEffect(() => {
        console.log("init");
        ;
        forminstance.setFieldsValue(_getCurrentUser());

    },[null]);
    const validateMessages = {
        required: '${label} is required!',
        types: {
          email: '${label} is not a valid email!',
          number: '${label} is not a valid number!',
        },
        number: {
          range: '${label} must be between ${min} and ${max}',
        },
      };
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };
      const onFinish = (values: any) => {
        console.log(values);
      };
    
    return <div className="rootdiv">
        <div className="usertitlecls">用户信息</div>
        <Form {...layout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}
           form={forminstance}
        >
      <Form.Item name="username" label="用户名：" rules={[{ required: true }]}
            >
        <Input />
      </Form.Item>
      <Form.Item name= 'showName' label="用户名称" rules={[{ type: 'email' }]}>
        <Input />
      </Form.Item>
      <Form.Item name='phone' label="手机号码" rules={[{ type: 'number', min: 0, max: 99 }]}>
        <Input />
      </Form.Item>
      <Form.Item name= 'email' label="邮箱：">
        <Input />
      </Form.Item>
      <Form.Item name= 'address' label="地址：">
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
        <Button type="primary" htmlType="submit">
          保存
        </Button>
      </Form.Item>
    </Form>
    </div>
}
export default UserCenter;