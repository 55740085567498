import { Carousel, Menu, Image, MenuProps, message } from "antd";
import { HomeOutlined, SnippetsOutlined, TeamOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import face from './res/face.jpeg';
import facecompare from './res/facecompare.jpeg';
import card from './res/card.jpeg';
import textocr from './res/textocr.jpeg';
import './index.css';
import { url } from "inspector";
import { Link, useNavigate } from "react-router-dom";
import { getCurrentUser, loginout } from "./api/headerapi";
import { clearCurrentUser, global_currentUser, _getCurrentUser, _getShowName } from "../context";
const Header: React.FC = () => {
  const [current, setCurrent] = useState<string>("home");
  const [loginMenu,setLoginMenu]=useState<any>();
  const [currentUser,setCurrentUser]=useState(null);
  const navigate = useNavigate();
  const menuCli = (e: any) => {
    setCurrent(e.key);
  }

  const loginoutfun= ()=>{
    clearCurrentUser();
    setCurrentUser(null);
    loginout(data=>{
      if(data.data.code==="13"){
        message.info(data.data.localeMessage);
       
      }
    });
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    let logincontent=(
      <Menu.Item key="login" icon={<TeamOutlined />}>
      <Link to={"/login"}>
        登录
      </Link>
    </Menu.Item>
  );
  const currentUsertmp = _getCurrentUser()
  setCurrentUser(currentUsertmp);
  
    if(currentUsertmp&&currentUsertmp!==null){
     
      logincontent=(
        <Menu.SubMenu title={currentUsertmp.showName} key="usersubmenu">
        <Menu.Item key="usercenter" icon={<TeamOutlined />}>
        <Link to={"/usercenter"}>
          {currentUsertmp.showName}
        </Link>
      </Menu.Item>
      
      <Menu.Item key="mydocument" icon={<TeamOutlined />}>
     
        <Link to={"/mydocument"}>
        我的文档
        </Link>
      </Menu.Item>
      <Menu.Item key="myresource" icon={<TeamOutlined />}>
     <Link to={"/myresource"}>
     我的资源
     </Link>
   </Menu.Item>
      <Menu.Item key="loginout" icon={<TeamOutlined />}>
        <span onClick={loginoutfun}>
          退出
        </span>
      </Menu.Item>
      </Menu.SubMenu>
      )
    }
      setLoginMenu(logincontent);
   
    
  setLoginMenu(logincontent);
    }, [global_currentUser]);
  
  return <div className="menusdiv">
    <span style={{"color":"#fff",float:"left",fontSize:"2em"}}>我的生活分享</span>
      <Menu selectedKeys={[current]} mode="horizontal" className="menustyle menustylehove " onClick={menuCli}>
        <Menu.Item key="home" icon={<HomeOutlined />}>
          <Link to={"/home"}>
            首页
          </Link>
        </Menu.Item>
        <Menu.Item key="document" icon={<SnippetsOutlined />}>
          <Link to={"document"}>
            博客
          </Link>
        </Menu.Item >
        <Menu.Item key="resource" icon={<SnippetsOutlined />}>
          <Link to={"resourcecenter"}>
            素材集
          </Link>
        </Menu.Item >
        {loginMenu}
      </Menu>
    </div>

}
export default Header;