import { useEffect, useState } from 'react';
import './index.css';
import { _getCurrentUser, _getShowName } from "../../context";


import React from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { Button, Checkbox, message, Space } from 'antd';
import { saveDocument, selectDocument, selectFileLabels } from './api/documentapi';
import { useParams } from 'react-router-dom';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { searchLabels } from '../document/api/labelapi';


const DocumentEdit: React.FC = (props: any) => {
  const params = useParams();
  const [documentId, setDocumentId] = useState<string>();
  const [fileTitle, setFileTitle] = useState<string>();
  const [fileContent, setFileContent] = useState<string>();
  const [document, setDocument] = useState({
    fileTitle: "",
    fileContent: ""
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([]);
  const handleChange = (tagtmp: any, checked: boolean) => {
    const nextSelectedTags = checked ? [...selectedTags, tagtmp] : selectedTags.filter(t => t.id !== tagtmp.id);
    setSelectedTags(nextSelectedTags);
  };
  const onSelect = () => {
    if (params.documentId&&params.documentId!==undefined) {
      selectDocument(params.documentId, data => {
        if (data.data) {
          setDocument(data.data);
          setFileTitle(data.data.fileTitle);
          setFileContent(data.data.fileContent);
          setIsOpen(data.data.openState.code === "YES" ? true : false);
         
        }
      }, faildata => {
        message.error(faildata);
      });
    }
  };
  const openStateonChange = (e: CheckboxChangeEvent) => {
    setIsOpen(e.target.checked);
  };
  const fileTileOnChange = (data: { target: { value: React.SetStateAction<string | undefined>; }; }) => {
    setFileTitle(data.target.value)
  }
  const fileContentOnChange = (data: { target: { value: React.SetStateAction<string | undefined>; }; }) => {
    setFileContent(data.target.value)
  }
  const existTag=(tag:any)=>{
    let res=false;
    selectedTags.forEach(selectTag=>{
      if(selectTag.id===tag.id){
        console.log("exist",tag,selectTag);
        res= true;
      }
    })
    return res;
  }
  const searchLables = () => {
   
    
    searchLabels("DOCUMENT",data => {
      if (data.data !== undefined) {
        
        
      setTags(data.data);

      }
      
    }, faildResp => { });

    if(params.documentId){
      selectFileLabels(params.documentId,data=>{
        if(data.data){
          const selectTagTmp:object[]=[];
          data.data.forEach((tagtmp:any)=>{
            console.log("selecttagtmp",tagtmp);
            selectTagTmp.push({
              id:tagtmp.labelId,
              labelName:tagtmp.labelName,
              labelType:tagtmp.labelType,
              seq:tagtmp.seq
            })
          });
          setSelectedTags(selectTagTmp);
        }
      },failddata=>{
    message.error(failddata);
      });
    }
  };
  const onSave = () => {
    let data: any = {};
    data.id = params.documentId;
    data.fileName = fileTitle;
    data.fileTitle = fileTitle;
    data.fileType = "txt";
    data.fileBrief = fileContent?.substring(0, 50);
    data.fileContent = fileContent;
    data.openState = isOpen ? "YES" : "NO";
    
    if(selectedTags){
      const labelManages:any[] = [];
      console.log(selectedTags);
      selectedTags.forEach(tag=>{
        labelManages.push({
          labelId:tag.id,
          labelName:tag.labelName,
          labelType:tag.labelType,
          seq:tag.seq
        })
      });
      data.labelManages=labelManages;
    }
    saveDocument(data, resp => {
      message.success("保存成功！");
    }, faildata => {
      message.error(faildata);
    })
  };
  useEffect(() => {
    setDocumentId(params.documentId);
    searchLables();
    onSelect();
  }, [params.documentId]);

  return <div>
    <div style={{ marginTop: "10px", marginLeft: "50px", marginRight: "50px", textAlign: "left" }}>
      <span ><Checkbox checked={isOpen} onChange={openStateonChange}>是否公开</Checkbox></span>
      <span style={{ marginLeft: "0%" }}>分类：
        {tags.map(tag => (
          <CheckableTag
            key={tag.id}
            checked={existTag(tag)}
            onChange={checked => handleChange(tag, checked)}
          >
            {tag.labelName}
          </CheckableTag>
        ))}
      </span>

      <Button type="primary" style={{ float: "right" }} onClick={onSave}>保存</Button>
    </div>
    <div style={{ marginLeft: "50px", marginRight: "50px", marginTop: "10px" }}>
      <TextArea placeholder="标题" autoSize={{ minRows: 1, maxRows: 4 }} value={fileTitle} onChange={fileTileOnChange} />
    </div>

    <div style={{ marginLeft: "50px", marginRight: "50px", marginTop: "5px" }}>
      <TextArea placeholder="请输入内容" autoSize={{ minRows: 5 }} value={fileContent} onChange={fileContentOnChange} />
    </div>
    <div style={{ marginLeft: "50px", marginRight: "50px", marginTop: "5px", textAlign: "left" }}>

    </div>


  </div>
}
export default DocumentEdit;