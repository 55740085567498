
import { Route, Router, Routes, useParams } from 'react-router-dom';
import './App.css';
import Banner from './banner';
import Body from './body';
import Footer from './footer';

import Header from './header';
import Home from './models/home';
import Document from './models/document';
import About from './models/about';
import Pay from './models/pay';
import Login from './models/Login';
import UserCenter from './models/usercenter';
import UserRegister from './models/userregister';
import SkuDetail from './models/sku';
import MyOrder from './models/myorder';
import OutSku from './models/outsku';
import MyDocument from './models/mydocument';
import DocumentEdit from './models/documentedit';
import DocumentView from './models/documentview';
import MdDocEdit from './models/mddocedit';
import MdDocView from './models/mddocview';
import MindDocEdit from './models/minddocedit';
import ImageDocEdit from './models/imageresedit';
import MyResource from './models/myresource';
import Resources from './models/resources';
import ResourcesCenter from './models/resources';
import H5App from './models/h5app';
function App() {
  let currenturl = window.location.href;
  console.log(currenturl);
  if(currenturl.indexOf("/web/sku")>0){
    return (
      <div className="App" >
       
        <Routes >
              <Route path="/web/sku/:userId" element={<OutSku></OutSku>}></Route>
           
          </Routes>
      </div>
    );
  }else{
  return (
    <div className="App" >
     
     <Header></Header>
      <Routes >
        <Route  path="/"  element={<Home></Home>}>
          <Route path='/home' element={<Home></Home>}></Route>
          </Route>
          <Route path='/login' element={<Login></Login>}></Route>
          <Route path='/usercenter' element={<UserCenter></UserCenter>}></Route>
          <Route path='/myorder' element={<MyOrder></MyOrder>}></Route>
          <Route path='/mydocument' element={<MyDocument></MyDocument>}></Route>
          <Route path='/h5app' element={<H5App></H5App>}></Route>
          <Route path='/myresource' element={<MyResource></MyResource>}></Route>
          <Route path='/documentedit/:documentId' element={<DocumentEdit></DocumentEdit>}></Route>
          <Route path='/documentview/:documentId' element={<DocumentView></DocumentView>}></Route>
          <Route path='/mddocview/:documentId' element={<MdDocView></MdDocView>}></Route>
          <Route path='/mddocedit' element={<MdDocEdit></MdDocEdit>}></Route>
          <Route path='/mddocedit/:documentId' element={<MdDocEdit></MdDocEdit>}></Route>
          <Route path='/minddocedit' element={<MindDocEdit></MindDocEdit>}></Route>
          <Route path='/documentedit' element={<DocumentEdit></DocumentEdit>}></Route>
          <Route path='/imagedocedit' element={<ImageDocEdit></ImageDocEdit>}></Route>
          <Route path='/imagedocedit/:id' element={<ImageDocEdit></ImageDocEdit>}></Route>
          <Route path='/userregister' element={<UserRegister></UserRegister>}></Route>
          <Route path='/document'element={<Document></Document>}></Route>
          <Route path='/resourcecenter'element={<ResourcesCenter></ResourcesCenter>}></Route>
          <Route path='/about' element={<About></About>}></Route>
   
          <Route path="/skudetail/:skuId" element={<SkuDetail></SkuDetail>}></Route>
            <Route path="/pay/lanchpay/:orderId" element={<Pay></Pay>}></Route>
   
            <Route path="/web/sku/:userId" element={<OutSku></OutSku>}></Route>
         
        </Routes>
        <Footer></Footer>
    </div>
  );
}
}

export default App;