import { Col, message, Row } from 'antd';
import QRCode from 'qrcode.react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { selectorder, selectPay } from './api/payapi';
import './index.css';
const Pay: React.FC = (props: any) => {
    const params = useParams();
    const [orderId, setOrderId] = useState<string>();
    const [orderInfo, setOrderInfo] = useState<any>();
    const [payInfo, setPayInfo] = useState<any>();
    const [timeId,setTimeId]=useState<any>();
    useEffect(() => {
        setOrderId(params.orderId);
        selectorder(params.orderId, data => {
            if (data.data.code !== '00001') {
                message.error(data.data.localeMessage);
                return;
            }
            if (!data.data.data) {
                message.error("未能找到订单信息");
                return;
            }
            setOrderInfo(data.data.data);
            selectPay(data.data.data.payId, datapay => {
                if (datapay.data.code !== '00001') {
                    message.error(datapay.data.localeMessage);
                    return;
                }
                if (!datapay.data.data) {
                    message.error("未能找到订单支付信息");
                    return;
                }
                setPayInfo(datapay.data.data);
                setTimeId( setInterval(
                    () => {
                        console.log("定时器")
                    },
                    1000
                ));
            }, faildata => { })
        }, faildata => {

        });

    }, [params.productId]);
    let qrdiv = (<div></div>);
    if (payInfo && payInfo.payUrl) {
        qrdiv = (<QRCode
            value={payInfo ? payInfo.payUrl : ""}  //value参数为生成二维码的链接
            size={200} //二维码的宽高尺寸
            fgColor="#000000"  //二维码的颜色
        />);
    }
    return <div >
        <div className='paytitle'>收银台</div>
        <div>
            <div>
                <Row>
                    <Col span={12} className='titlecls'>订单编号：</Col>
                    <Col span={12} className='contentcls'>{orderInfo ? orderInfo.orderNo : ""}</Col>
                </Row>
                <Row>
                    <Col span={12} className='titlecls'>商品名称：</Col>
                    <Col span={12} className='contentcls'>{orderInfo ? orderInfo.skuName : ""}</Col>
                </Row>
                <Row>
                    <Col span={12} className='titlecls'>应付金额：</Col>
                    <Col span={12} className='contentcls'><span className='moneycls'>¥{orderInfo ? orderInfo.price / 100 : ""}</span></Col>
                </Row>

            </div>
        </div>
        <div>
            <div><div className='weixinpaytitle'>微信支付</div>
                <div >
                    {qrdiv}
                </div>
            </div>
        </div>
    </div>
}
export default Pay;