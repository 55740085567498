import { useEffect, useState } from 'react';
import { _getCurrentUser, _getShowName } from "../../context";
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import './index.css';
import React from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { Button, message, Space } from 'antd';
import { useParams } from 'react-router-dom';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
// import style manually
import 'react-markdown-editor-lite/lib/index.css';
import Modal from 'antd/lib/modal/Modal';
import { postFile } from '../../util/request';
import { saveDocument, selectDocument } from '../documentedit/api/documentapi';



const MdDocView: React.FC = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileTitle, setFileTitle] = useState<string>();
  const [fileContent, setFileContent] = useState<string>();
  const [fileBrief, setFileBrief] = useState<string>();
  const [fileImg, setFileImg] = useState<string>();
  const [documentId, setDocumentId] = useState<string>();
  const [document1, setDocument1] = useState<any>();
  const params = useParams();
  
  const onSelect = () => {
    if (params.documentId) {
      selectDocument(params.documentId, data => {
        if (data.data) {
          setDocument1(data.data);
          setFileTitle(data.data.fileTitle);
          
          document.title = data.data.fileTitle;
          let keys="";
          if(data.data.labelManages){
            data.data.labelManages.forEach((element:any) => {
              keys+=element.labelName+",";
            });
          }
          document.querySelector('meta[name="keywords"]')?.setAttribute('content',keys);
          document.querySelector('meta[name="description"]')?.setAttribute('content',data.data.fileBrief);
          setFileContent(data.data.fileContent);
        }
      }, faildata => {
        message.error(faildata);
      });
    }
  };
 
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
 
  const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
    breaks:true,
    highlight: function (str, lang) {
      if (lang  && hljs.getLanguage(lang)) {
        try {
          return '<pre class="hljs codeStyle"><code>' +
          hljs.highlight(str,{ language: lang, ignoreIllegals: true }).value+
          '</code></pre>';
        } catch (__) {}
      }
  
      return '<pre class="hljs codeStyle"><code>' +str + '</code></pre>';
    }
  }).enable('image');
  useEffect(() => {
    setDocumentId(params.documentId);
    onSelect();

  }, [params.documentId]);

  return <div>
    <div style={{ marginLeft: "50px", marginRight: "50px", marginTop: "10px" }}>
      <div placeholder="标题" ><h1>{fileTitle}</h1></div>
    </div>
    <MdEditor 
      value={fileContent}
      renderHTML={text => mdParser.render(text)}
      htmlClass="mdview"
      view={{menu:false,md:false,html:true}}

    />

  </div>
}
export default MdDocView;