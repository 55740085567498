export var global_currentUser:any;
export function _setCurrentUser(data: any){
    console.log("store user",data);
    localStorage.setItem("currentUser",JSON.stringify(data));
    global_currentUser=data;
}
export function _getCurrentUser(){
    let currentUserStr = localStorage.getItem("currentUser");
    // console.log("store _getCurrentUser",currentUserStr);
    if(currentUserStr){
        return JSON.parse(currentUserStr);
    }
}
export function clearCurrentUser(){
    localStorage.clear();
    global_currentUser=undefined;
}
export function _getShowName(){
    console.log("store _getShowName",global_currentUser);
    let currentUserStr = localStorage.getItem("currentUser");
    if(currentUserStr){
        global_currentUser = JSON.parse(currentUserStr);
    return global_currentUser.showName;
    }

}
export function _getCurrentUserId(){
    console.log("store _getCurrentUserId",global_currentUser);
    let currentUserStr = localStorage.getItem("currentUser");
    if(currentUserStr){
        global_currentUser = JSON.parse(currentUserStr);
        return global_currentUser.userId;
    }

}