import { Button, Col, Layout, message, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './index.css';
import V4 from './res/V4.jpg';
import V1 from './res/V1.jpg';
import V2 from './res/V2.jpg';
import V3 from './res/V3.jpg';
import { insertOrder, selectsku } from './api/skuapi';
import Sider from 'antd/lib/layout/Sider';
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import {  _getCurrentUser, _getShowName } from "../../context";
const SkuDetail: React.FC = (props:any) => {
 const params = useParams();
    const [skuId, setSkuId] = useState<string>( );
    const navigate = useNavigate();
    const[skuDetail,setSkuDetail]=useState<any>({});
    const [timeId,setTimeId]=useState<any>();
    useEffect(() => {
        setSkuId(params.skuId);
        selectsku(params.skuId,data=>{
            console.log(data);
            setSkuDetail(data.data.data);
            setTimeId( setInterval(
                () => {
                    console.log("定时器")
                },
                1000
            ));
        },faildata=>{
            message.error(faildata);
        })
    }, [params.skuId])
    const payfun=()=>{
        if(!_getCurrentUser()){
            message.error("当前用户未登录，请登录后进行后买")
            return;
        }
        insertOrder({
            skuId:skuId
        },data=>{
           if(data.data.code!=='00001'){
               message.error("下单失败，请重试！")
                return;
           }
            navigate("/pay/lanchpay/"+data.data.data.id);
        },faildata=>{
            message.error(faildata);
        })
   
    }
    return <div >
            <Row>
            <Col span={8} className='skuimagecol'>
        <img alt='商品图片' className='skuimage' src={skuDetail.skuImage==='1'?V1:(skuDetail.skuImage==='2'?V2:(skuDetail.skuImage==='3'?V3:(skuDetail.skuImage==='4'?V4:V1)))}></img>
        </Col>
        <Col  span={16}>
            <div className='contenttxt'>
         <Space direction="vertical" align='start' size="middle">
       <div className='contenttxtdiv contentnamediv'>{skuDetail?skuDetail.name:""}</div>
       <div className='contenttxtdiv'>描述：{skuDetail?skuDetail.describe:""}</div>
       <div className='contenttxtdiv'>价格：<span className='skuprice'>¥{skuDetail?skuDetail.price/100:""}</span></div>
       <div className='contenttxtdiv'><Button type='primary' onClick={payfun}>购买</Button></div>
       </Space>
       </div>
       </Col>
        </Row>
    </div>
}
export default SkuDetail;