import { Avatar, Card, Col, Pagination, Radio, Row, Space } from 'antd';
import Layout from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import { Image } from 'antd';

import { JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { searchLabels, searchOpenResource } from './api/labelapi';
import './index.css';
import V4 from './res/V4.jpg';
import V1 from './res/V1.jpg';
import V2 from './res/V2.jpg';
import V3 from './res/V3.jpg';
import Meta from 'antd/lib/card/Meta';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
const siderStyle: React.CSSProperties = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#fff',
};
const ResourcesCenter: React.FC = () => {
    const [labels, setLabels] = useState<any>();
    const [resourceContent, setResourceContent] = useState<any>();
    const [total, setTotal] = useState<number>();
    const [selectLableId, setSelectLabelId] = useState<number>();
    const searchResource = (page: number, pageSize: number) => {
        const searchData: any = {
            labelId:selectLableId
        };
        searchOpenResource(searchData, page, pageSize, data => {
            const resourcelist = data.data.data;
            if (!resourcelist) {
                return;
            }
            setTotal(data.data.total);
            const resourceContentTmp: any[] = [];
            resourcelist.forEach((element: any) => {
                resourceContentTmp.push(<>
                    <Col span={6}>
                        <Card
                            cover={
                                <Image
                                    alt={element.imageName}
                                    src={"/file/file/showthumbnail/" + element.fileId}
                                    preview={{
                                        src:"/file/file/download/" + element.fileId
                                    }
                                    }
                                    />
                            }
                            actions={[
                                // <SettingOutlined key="setting" />,
                                // <EditOutlined key="edit" />,
                                // <EllipsisOutlined key="ellipsis" />,
                            ]}
                        >
                            <Meta
                                // avatar={<Avatar src="https://joesch.moe/api/v1/random" />}
                                title={element.resTitle}
                                description={(<><div style={{"fontSize":"3px"}}>{element.createDate}</div>
                                <div>{element.resBrief}</div></>)}
                            />
                        </Card>
                    </Col>
                </>)
            });
            setResourceContent(resourceContentTmp);
        }, faildata => { })
    }
    useEffect(() => {
        searchLabels("RESOURCE", data => {
            if (data.data !== undefined) {

                const labelContent: JSX.Element[] = [];
                data.data.forEach((element: {
                    labelName: any; id: any;
                }) => {
                    labelContent.push(<Radio.Button value={element.id} className='documentLabelcls'>{element.labelName}</Radio.Button>)
                });
                setLabels(labelContent);
            }

        }, faildResp => { });



    }, []);
    useEffect(() => {
        searchResource(1,36);
    }, [selectLableId]);
    return <div className='documentdiv'>
        <div>
            <Radio.Group defaultValue=""
                onChange={e => {
                    setSelectLabelId(e.target.value);
                }}
                buttonStyle="solid" className='documentLabelcls'>
                <Space>
                    <Radio.Button value="" className='documentLabelcls'>全部</Radio.Button>
                    {labels}
                </Space>
            </Radio.Group>
        </div>

        <Row gutter={[18, 18]} style={{marginTop:"10px"}}>
            {resourceContent}
        </Row>
        <Pagination onChange={(page: number, pageSize: number) => { searchResource(page, pageSize) }} style={{ textAlign: "right", marginRight: "10%", marginTop: "6px", fontSize: "12px" }} pageSize={36} defaultCurrent={1} total={total} />
    </div>
}
export default ResourcesCenter;