import axios from 'axios'
//创建一个axios
const instance = axios.create({
    baseURL:"",
    timeout:30000,
    headers:{"Content-Type":"application/json"}
})
 
//定义一些请求方法
export function get(url: string,... params: any){
    return instance.get(url,{
        params
    })
}
 
export function post(url: string,data: any){
    
    return instance.post(url,data)
}
 
export function put(url: string,data: any){
    return instance.put(url, data)
}
 
export function del(url: string){
    return instance.delete(url)
}
export function postFile(url:string,param: any){
 // 添加请求头
 return axios.post(url,param,{
    headers:{'Content-type' : 'multipart/form-data' }
})
}
