import { useEffect, useState } from 'react';
import { _getCurrentUser, _getShowName } from "../../context";

import './index.css';
import React from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { Button, message, Space } from 'antd';
import { saveDocument, selectDocument } from '../documentedit/api/documentapi';
import { useParams } from 'react-router-dom';

const DocumentView: React.FC = (props: any) => {
  const params = useParams();
  const [documentId, setDocumentId] = useState<string>();
  const [fileTitle,setFileTitle]=useState<string>();
  const [fileContent,setFileContent]=useState<string>();
  const [document,setDocument]=useState({
    fileTitle:"",
    fileContent:""
  });
  const onSelect = () => {
    console.log(params.documentId);
    if(params.documentId){
    selectDocument(params.documentId,data=>{
      if(data.data){
        setDocument(data.data);
        setFileTitle(data.data.fileTitle);
        setFileContent(data.data.fileContent);
      }
    }, faildata => {
      message.error(faildata);
    });
  }
  };
  
  useEffect(() => {
    setDocumentId(params.documentId);
    onSelect();
  }, [ params.documentId]);

  return <div>
        
        <div style={{marginLeft:"50px",marginRight:"50px",marginTop:"10px"}}>
        <div><h1>{fileTitle}</h1></div>
        </div>
        <div style={{marginLeft:"50px",marginRight:"50px",marginTop:"5px"}}>
            <div className='textcontent' style={{"textAlign":"left"}}>
            {fileContent}
        </div>
        </div>
      
 
  
  </div>
}
export default DocumentView;