import { Avatar, Button, Col, Dropdown, Layout, List, Menu, MenuProps, message, Row, Space, Table, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './index.css';
import defaultAvatar from './res/random.svg';
import { _getCurrentUser, _getShowName } from "../../context";
import { Input } from 'antd';


import { deleteDocument, searchDocument } from './api/documentapi';
import React from 'react';
interface DataType {
  id: number;
  fileName: string;
  fileTitle: string;
  fileType: string;
  fileBrief: string;
  fileContent: string;
  fileTopImage: string;
}

// eslint-disable-next-line react-hooks/rules-of-hooks
const IconText = ({ icon, text }: { icon: React.FC; text: string }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);
const menu = (
  <Menu>
    <Menu.Item>
      <a target="_self" rel="普通文本" href="/documentedit">
        文本
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_self" rel="markdown文本" href="/mddocedit">
        markdown
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_self" rel="img" href="/imagedocedit">
      Image
      </a>
    </Menu.Item>
  </Menu>
);
const { Search } = Input;

const MyDocument: React.FC = (props: any) => {
  const [myDocumentList, setMyDocumentList] = useState([]);
  const [currentTotal, setCurrentTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onSearchEvent = (value: string) => {
    onSearch(page,pageSize,value);
  };
  const onSearch = (pagetmp:number, pageSizeTmp:number,val?:string) => {
    const searchData = {"key":val};
    searchDocument(page, pageSize,searchData, data => {
      setCurrentTotal(data.data.total);
      setMyDocumentList(data.data.data);

    }, faildata => {
      message.error(faildata);
    });
  };
  const onDelete=(documentId:number)=>{
    deleteDocument(documentId,()=>{
      message.success("删除成功");
      onSearch(1,pageSize);
    },faildata=>{
      message.error(faildata.data);
    })
  }
  useEffect(() => {
    onSearch(page,pageSize)

  }, []);

  return <div>
    <div style={{marginTop:"3px"}}>
    
 <Space 
 direction="horizontal"
 align='end'
 >
      <Search
        placeholder="input search text"
        allowClear
        enterButton="Search"
        size="small"
        onSearch={onSearchEvent}
      />
     
      <Dropdown overlay={ menu } placement="bottom"  >
        <Button type="primary"   size='small'>新增</Button>
      </Dropdown>
      </Space>
    </div>
    <List
      itemLayout="vertical"
      size="large"
      pagination={{
        onChange: (page) => {
          setPage(page);
          onSearch(page,pageSize);
        },
        pageSize: pageSize,
        total: currentTotal,
        showTotal: (total) => `Total ${total} items`
      }}
      
      dataSource={myDocumentList}
      renderItem={(item: DataType) => (
        <List.Item
          key={item.id}
          style={{ textAlign: 'left' }}
          actions={[
            <a key="list-loadmore-edit" href={(item.fileType === "md" ? "mddocedit/":"documentedit/") + item.id}>edit</a>,
            <a key="list-loadmore-delete" onClick={()=>{onDelete(item.id)}}>delete</a>,
            //   <IconText icon={LikeOutlined} text="156" key="list-vertical-like-o" />,
            //   <IconText icon={MessageOutlined} text="2" key="list-vertical-message" />,
          ]}

          extra={
            item.fileTopImage ? <img
              style={{"maxHeight":"130px"}}
              alt="logo"
              src={item.fileTopImage}
            /> : ""
          }
        >
          <List.Item.Meta
            avatar={<Avatar src={defaultAvatar} />}
            title={<a href={ (item.fileType==="md"?"mddocedit/":"documentedit/") + item.id}>{item.fileTitle}</a>}
            description={item.fileBrief}
          />
        </List.Item>
      )}
    />
  </div>
}
export default MyDocument;