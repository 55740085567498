// @ts-ignore
/* eslint-disable */
import { AxiosResponse } from 'axios';
import {get} from '../../util/request';

export const getCurrentUser=(success: (arg0: AxiosResponse<any, any>) => void,failed: (reason: any) => void)=>{
    get("/rest/login/getCurrentUser").then(
        success
)
.catch(failed)
;
}
export const loginout=(success: (arg0: AxiosResponse<any, any>) => void)=>{
    get("/rest/loginout").then(
        success
);
}