import { Button, Col, Layout, message, Row, Space, Table, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './index.css';

import {  _getCurrentUser, _getShowName } from "../../context";
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import Column from 'antd/lib/table/Column';
import { searchMyOrder } from './api/orderapi';
interface DataType {
    key: React.Key;
    firstName: string;
    lastName: string;
    age: number;
    address: string;
    price:number;
    tags: string[];
    payStateName: string;
    payState: string;
    payId:string;
    id:string;
  }
  
  // eslint-disable-next-line react-hooks/rules-of-hooks
  
 
const MyOrder: React.FC = (props:any) => {
    const [currentPage,setCurrentPage]=useState(1);
    const [currentSize,setCurrentSize]=useState(2);
    const [currentTotal,setCurrentTotal]=useState(0);
    const [myorderList,setMyOrderList]= useState([]);
  
  const searchfun=(pagination:any)=>{
      console.log(pagination);
      setCurrentPage(pagination.current);
      setCurrentSize(pagination.pageSize);
    searchMyOrder(pagination.current,pagination.pageSize,data=>{
        if(data.data.code==='00001'){
            setCurrentTotal(data.data.data.total);
          setMyOrderList(data.data.data.data);
        }else{
          message.error(data.data.localeMessage);
        }
    },faildata=>{
        message.error(faildata);
    })
  }
  useEffect(() => {
  searchfun({
    current:1,
    pageSize:2
});},[]);
    return <div>
        <Table dataSource={myorderList}
        onChange={searchfun}
        pagination={{
            showSizeChanger: true,
            showQuickJumper: false,
            defaultPageSize: currentPage,
            pageSizeOptions: ['20', '30', '40'],
            pageSize: currentSize,
            current: currentPage,
            total: currentTotal
        }}
        >
      <Column title="订单编号" dataIndex="orderNo" key="orderNo" />
      <Column title="商品名称" dataIndex="skuName" key="skuName" />
 
    <Column title="商品价格" dataIndex="price" key="price" 
    render={(_: any, record: DataType) => (
     
        <span>￥{record.price/100}</span>
       
      )}
    />
    <Column title="下单时间" dataIndex="orderDate" key="orderDate" />
    <Column title="付款状态" dataIndex="orderDate" key="orderDate"
      render={(_: any, record: DataType) => (
        <span>{record.payStateName}</span>
      )}
    />
    
    <Column
      title="操作"
      key="action"
      render={(_: any, record: DataType) => (
        record.payState==='WAIT_PAY'?
        <Space size="middle">
          <Link to={"/pay/lanchpay/"+record.id}>
        付款
      </Link>
        </Space>
        : <span></span>
      )}
    />
  </Table>
    </div>
}
export default MyOrder;